import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import LocalizedLink from "../components/localizedLink"
import slugify from "slugify"
import Trial from "../components/trial"

const UsecasesPage = ({
  data,
  pageContext: { locale, uid, alternateLanguages },
  location,
}) => {
  const UsecasesPageData = data.prismic.usecasespage
  return (
    <>
      <SEO
        title={UsecasesPageData.meta_title}
        desc={UsecasesPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: UsecasesPageData.title,
          subtitle: UsecasesPageData.subtitle,
          image: {
            image: UsecasesPageData.main_image,
            sharp: UsecasesPageData.main_imageSharp,
          },
        }}
      />

      <section className="section is-medium">
        <div className="container" css={useCasesPageCss}>
          <div className="columns is-multiline">
            {UsecasesPageData.use_cases.map(usecase => {
              const usecaseDetailSlug = uid + "/" + slugify(usecase.use_case_id)

              return (
                <div key={usecase.use_case_id} className="column is-a-third">
                  <LocalizedLink to={usecaseDetailSlug}>
                    <div className="shape">
                      <svg
                        width="220"
                        height="482"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <defs>
                          <linearGradient
                            id="linearGradientOrange"
                            y2="0%"
                            x2="39.5795336%"
                            y1="100%"
                            x1="60.4204664%"
                          >
                            <stop offset="0%" stopColor="#FD7543" />
                            <stop offset="100%" stopColor="#DE4E4E" />
                          </linearGradient>
                        </defs>
                        <g>
                          <rect
                            fill="none"
                            id="canvas_background"
                            height="602"
                            width="802"
                            y="-1"
                            x="-1"
                          />
                        </g>
                        <path
                          id="Selected-Color"
                          d="m101.11542,480.03914c5.7348,2.49177 12.2515,2.49177 17.9863,0l91.90837,-46.35681c5.2925,-3.06087 8.67458,-8.58008 8.98991,-14.67061l0,-356.1133c-0.31651,-6.09019 -3.69991,-11.60835 -8.99315,-14.66738l-92.01208,-46.36327c-5.73622,-2.49036 -12.25332,-2.49036 -17.98954,0l-92.01208,46.36327c-5.29324,3.05903 -8.67664,8.57719 -8.99315,14.66738l0,356.10684c0.31593,6.09039 3.70112,11.60827 8.99639,14.66415l92.11903,46.36973z"
                        />
                      </svg>
                      <article className="container shape__container">
                        {usecase.use_case_image && (
                          <div className="block">
                            <figure className="image shape__image">
                              <Image
                                sharp={usecase.use_case_imageSharp}
                                image={usecase.use_case_image}
                              />
                            </figure>
                          </div>
                        )}
                        <div className="content">
                          <h3 className="title is-uppercase is-6">
                            {RichText.asText(usecase.use_case_title)}
                          </h3>
                          {usecase.use_case_subtitle && (
                            <p className="has-text-weight-light subtitle is-size-7">
                              {RichText.asText(usecase.use_case_subtitle)}
                            </p>
                          )}
                        </div>
                        <div className="button is-primary is-outlined is-rounded has-text-link has-background-white">
                          {usecase.use_case_button}
                          <span className="icon-arrow_right pl-2"></span>
                        </div>
                        {/* <a href={usecaseSlug}>lol</a> */}
                      </article>
                    </div>
                  </LocalizedLink>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
    </>
  )
}

export default UsecasesPage

UsecasesPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const usecasesQuery = graphql`
  query UsescasesQuery($uid: String!, $locale: String!) {
    prismic {
      usecasespage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        use_cases {
          use_case_id
          use_case_title
          use_case_subtitle
          use_case_content
          use_case_button
          use_case_image
          use_case_imageSharp {
            extension
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      ...Trial
    }
    site {
      ...SiteInformation
    }
  }
`

const useCasesPageCss = css`
  .shape {
    position: relative;
    text-align: center;

    path {
      fill: #fff;
    }

    &:hover {
      .title,
      .subtitle {
        color: #fff;
      }
      path {
        fill: url(#linearGradientOrange);
      }
    }
  }

  .icon-arrow_right {
    padding-left: 10px;
  }

  .shape__container {
    position: absolute;
    top: 0%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    max-width: 200px;

    .button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 400px;
    }

    .content {
      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  .shape__image {
    width: 200px;
    padding: 10px 0;
  }
`
